import React, {useState, useEffect, useContext} from "react";
import $ from "jquery";
import Modal from "react-modal";
import LabelCheckerLabelling from "../LabelChecker/LabelCheckerLabelling";
import { getFetch } from "../../utils/fetchUtils";
import { UserContext } from "../../context/userContext";
import Select from "react-select";
import { addSpinner, removeSpinner } from "../../utils/helpers";

const AWS = require("aws-sdk");

const s3 = new AWS.S3({
  accessKeyId: "AKIA4ZN2FXCSVWP23YHK", secretAccessKey: "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", useAccelerateEndpoint: false, signatureVersion: "v4", region: "eu-central-1"
});

const MAX_FEEDBACK = 21;

type Label = {
  label_id: number,
  transformed: boolean,
  avoidable: boolean
}

type Feedback = {
  affected_trashevents: any[],
  final_labels: {
    labels: Label[],
    errors: number[]
  },
  user_labels: {
    labels: Label[],
    errors: number[]
  }
}

const customModalStyles = {
  content: {
    width: "104vh",
    height: "88vh",
    left: "50%",
    top: "53%",
    transform: "translate(-50%, -50%)",
  }
};

function AgentFeedbackSection({agentFeedbackData, getURL, toggleImageModal, allLabelData, errorData}) {
  // Remove the "overflow: hidden" from <body> and put it back on unmount
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflowY = "auto";
    return () => { document.getElementsByTagName("body")[0].style.overflowY = "hidden"; };
  });

  return (
    <div className='feedbackLabelBoxesContainer'>
      {agentFeedbackData.length
        ? generateFeedbackLabelBoxes(agentFeedbackData, getURL, toggleImageModal, allLabelData, errorData)
        : <p style={{paddingLeft: "15px", marginTop: "15px"}}>There is no feedback for this user currently: not enough labellings have been reviewed.</p>}
    </div>
  );
}

function FeedbackImageBox({imageList, getURL, toggleImageModal}) {
  const imageComponentList = [];

  for (const image in imageList) {
    const imgURL = getURL(imageList[image].image_id);
    const prevImgURL = getURL(imageList[image].prev_image_id);

    imageComponentList.push(<img key={imageList[image].image_id} className={"feedbackImage"} src={imgURL} alt="Example 1" onClick={() => toggleImageModal(imageList[image].feedback_index, imgURL, prevImgURL, image)}/>);
  }

  return <div
    style={{
      "height": "auto", "width": "calc( 100% - 30px )", "margin": "15px"
    }}>
    {imageComponentList}
  </div>;
}

function LabelledAsComponent({labelList}) {
  const labelledAs = [];

  labelledAs.push(<div>You labelled this as:</div>);

  const listItems = [];
  for (const label in labelList)
    listItems.push(<li key={label} className={"labelElement"}>{labelList[label]}</li>);


  labelledAs.push(<ul className={""} style={{"display": "list-element"}}>{listItems}</ul>);

  return <div>
    {labelledAs}
  </div>;
}

function createLabelString(labelId, isTransformed, isAvoidable, allLabelData) {
  const labelData = allLabelData[labelId];
  if (labelData === undefined)
    return "[unknown label]";
  let labelStr = `${isAvoidable ? "edible" : "inedible"} > ${labelData.class} > ${labelData.group} > ${labelData.subgroup} > ${labelData.description}`;
  const transformedType = isTransformed ? labelData.transformed : labelData.untransformed;
  if (transformedType) labelStr += " > " + transformedType;
  return labelStr;
}

function generateFeedbackLabelBoxes(agentFeedbackData, getURL, toggleImageModal, allLabelData, errorData) {
  const boxes = [];

  for (let i = 0; i < Math.min(agentFeedbackData.length, MAX_FEEDBACK); i++) {
    const labelList = [];
    const imageList = [];
    const finalLabels = [];
    let nr_affected = 0;

    const agentFeedback = agentFeedbackData[i];
    nr_affected = agentFeedback.affected_trashevents.length;

    const maxLabels = Math.min(agentFeedback.user_labels.length, 5);
    for (let i = 0; i < maxLabels; i++) {
      const labelling = agentFeedback.user_labels[i];

      const userLabelStr = [];
      for (const label of labelling.labels) {
        const lenStr = userLabelStr.length;
        userLabelStr.push(<div key={lenStr}>{lenStr ? "AND" : ""} {createLabelString(label.label_id, label.transformed, label.avoidable, allLabelData)}</div>);
      }
      for (const error of labelling.errors)
        userLabelStr.push(<div key={"error" + error} className="feedbackError">AND error: {errorData[error]}</div>);


      labelList.push(userLabelStr);
    }

    for (const [ind, finalLabelling] of agentFeedback.final_labels.labels.entries()) {
      const labelStr = createLabelString(finalLabelling.label_id, finalLabelling.transformed, finalLabelling.avoidable, allLabelData);
      finalLabels.push(<li key={ind}>{labelStr}</li>);
    }

    for (const finalError of agentFeedback.final_labels.errors)
      finalLabels.push(<li className="feedbackError" key={"error" + finalError}>error: {errorData[finalError]}</li>);


    // Show max 4 images
    for (let j = 0; j < Math.min(4, nr_affected); j++) {
      imageList.push({
        "image_id": agentFeedback.affected_trashevents[j].image_id,
        "prev_image_id": agentFeedback.affected_trashevents[j].prev_image_id,
        "trashevent_id": agentFeedback.affected_trashevents[j].trashevent_id,
        "feedback_index": i
      });
    }

    boxes.push(<div key={i} className={"stdBox feedbackLabelBoxes"}>
      <h3 style={{"paddingTop": "15px"}}><ul><b>{finalLabels}</b></ul></h3>
      <span
        style={{
          "fontSize": "0.9em", "position": "relative", "left": "75%"
        }}>Occurred <b>{nr_affected}</b> times</span>
      <FeedbackImageBox imageList={imageList} getURL={getURL} toggleImageModal={toggleImageModal}/>
      <LabelledAsComponent labelList={labelList}/>
    </div>);
  }

  return boxes;
}

function AgentFeedback(props) {
  // Initialize state
  const [agentFeedbackData, setAgentFeedbackData] = useState<Feedback[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [fullLabelData, setFullLabelData] = useState({});
  const [errorData, setErrorData] = useState({});
  const {user} = useContext(UserContext);
  const [agentList, setAgentList] = useState([]);
  const [modalData, setModalData] = useState({
    trasheventData: null,
    imageURL: "",
    prevImageURL: "",
    title: ""
  });
  const [selectedUser, setSelectedUser] = useState(
    {
      value: user.id,
      label: user.username,
      level: user.currentRole === "Labelling" ? user.labellingInfo.level : user.segmentationInfo.level,
      currentRole: user.currentRole
    });

  useEffect(() => {
    $("#root").css("background-color", "#fafafa");

    loadLabels();
    loadErrors();
    loadAgentUser();

  }, [user.username]);

  useEffect(() => {
    // load agent feedback
    if (selectedUser.label === "")
      return;
    document.getElementById("overlay").style.display = "block";
    addSpinner($("body"));

    getFetch(`/get-agent-feedback?user_id=${selectedUser.value}`, {})
      .then((data) => {
        setAgentFeedbackData(data);
        removeSpinner($("body"));
        document.getElementById("overlay").style.display = "none";
      });
  }, [selectedUser]);

  const getURL = (image_key) => {
    const params = {Bucket: "kitroengine-s3", Key: image_key};
    const url = s3.getSignedUrl("getObject", params);
    return url;
  };

  const loadLabels = () => {
    getFetch("/get-labels", {})
      .then((data) => {
        setFullLabelData(data.allLabels);
      });
  };

  const loadErrors = () => {
    getFetch("/get-errors", {})
      .then((data) => {
        setErrorData(data.errors.reduce((acc, curr) => ({...acc, [curr.id]: curr.error_name}), {}));
      });
  };

  const loadAgentUser = () => {
    getFetch("/get-agent-list", {})
      .then((data) => {
        setAgentList(data.map((item) => ({
          value: item.id,
          label: item.username,
          level: item.data.currentRole === "Labelling" ? item.data.labellingInfo.level : item.data.segmentationInfo.level,
          currentRole: item.data.currentRole
        })));
      });
  };

  const handleUsernameChange = (username) => {
    setSelectedUser(username);
  };

  const currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, "/");

  const toggleImageModal = (feedbackIndex, imgURL, prevImgURL, trasheventIndex) => {
    const feedbackData = agentFeedbackData[feedbackIndex];
    const trashevent = feedbackData.affected_trashevents[trasheventIndex];

    const trasheventDate = new Date(trashevent.datetime);

    const newModalData = {
      trasheventData: {
        device_id: trashevent.device_id,
        weight: "N/A ",
      },
      imageURL: imgURL,
      prevImageURL: prevImgURL,
      title: trasheventDate.toString().split("GMT")[0],
    };

    setModalData(newModalData);
    setModalOpen(true);

  };

  return (
    <div className="animated fadeIn agentFeedbackMaster">
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(!modalOpen)}
        contentLabel="LabellingModal"
        style={customModalStyles}
      >
        <LabelCheckerLabelling
          trasheventData={modalData.trasheventData}
          imageURL={modalData.imageURL}
          prevImageURL={modalData.prevImageURL}
          title={modalData.title}
          labelData={fullLabelData}
          labellingEnabled={false}
        />
      </Modal>
      <div className="stdBox feedbackHeader">
        <h3 style={{"margin": "0px", "display": "inline-block"}}><b>How can you improve your labelling?</b></h3>
        <div
          style={{
            "margin": "0px", "display": "flex", "alignItems": "baseline"
          }}>
          <span className={"agentFeedbackHeaderElement"}>Last updated: <b>{currentDate}</b></span>
          <span className={"agentFeedbackHeaderElement"}>Level: <b>{selectedUser.level}</b></span>
          <span className={"agentFeedbackHeaderElement"}>Current Role: <b>{selectedUser.currentRole}</b></span>
          <span className={"agentFeedbackHeaderElement"} style={{"display": "flex", "alignItems": "baseline"}}>
            Agent: &nbsp;
            {user.labellingInfo.level === 3
              ? <Select
                name="selectViewmode"
                key="selectViewmodeUsernames"
                isMulti={false}
                isClearable={false}
                options={agentList}
                placeholder="All"
                onChange={handleUsernameChange}
                value={selectedUser}
                closeMenuOnSelect={true}
                className="select-dropdown-std"
              />
              : <b>{user.username}</b> }
          </span>
        </div>
      </div>
      <AgentFeedbackSection
        agentFeedbackData={agentFeedbackData}
        getURL={getURL}
        toggleImageModal={toggleImageModal}
        allLabelData={fullLabelData}
        errorData={errorData}/>
    </div>
  );
}

export default AgentFeedback;
