import React, { Component } from "react";
import $ from "jquery";

import ImageContainer from "./ImageContainer.js";
import LabelsContainer from "./LabelsContainer.tsx";
import LabelingSelector from "./LabelingSelector.tsx";
import LiveCountComponent from "../LiveCountComponent/LiveCountComponent";

import {HotKeys} from "react-hotkeys";
import { getFetch } from "../../utils/fetchUtils.js";

const AWS = require("aws-sdk");

// TODO: Remove access keys from Frontend
const s3 = new AWS.S3({
  accessKeyId: "AKIA4ZN2FXCSVWP23YHK", secretAccessKey: "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", useAccelerateEndpoint: false, signatureVersion: "v4", region: "eu-central-1"
});

const map = {
  "fullPercent1": "alt+y",
  "fullPercent2": "alt+x",
};

const BatchInfo = ({outlet_name, property_type, area_name, close_fn}) => <div
  style={{
    position: "absolute", width: "100%", height: "100%", zIndex: "105"
  }}>
  <div
    style={{
      position: "absolute", top: "30%", left: "50%", width: "40%", transform: "translate(-50%, -50%)", backgroundColor: "white", border: "1px solid gray", borderRadius: "0.5em", padding: "2em"
    }}>
    <p>
      You are about to start of batch of pictures from the <b>{outlet_name}</b> outlet
      which is a <b>{property_type}</b> deployment captured by a device
      located in the <b>{area_name}</b> area.
    </p>
    <button onClick={close_fn} className="btn btn-primary overviewButton buttonColor">OK</button>
  </div>
</div>;

class Labelling extends Component {
  constructor(props) {
    super(props);

    this.nextImage = this.nextImage.bind(this);
    this.prevImage = this.prevImage.bind(this);
    this.imageData = this.imageData.bind(this);
    this.checkDisplay = this.checkDisplay.bind(this);
    this.weightAllocation = this.weightAllocation.bind(this);
    this.getDateString = this.getDateString.bind(this);
    this.getURL = this.getURL.bind(this);
    this.liveCountRef = React.createRef();
    this.selectedLabeling = this.selectedLabeling.bind(this);
    this.getFilteredLabels = this.getFilteredLabels.bind(this);

    this.state = {
      imageData: [],
      labelData: new Set(),
      markedDontKnow: [],
      currentIndex: 0,
      currentLabelIndex: 1,
      imageSwitching: false,
      viewMode: "L",
      labelComplete: true,
      resetUI: 0,
      batchInfo: {},
      batchData: {},
      labels: [],
      batchSize: null,
      checkBatch: false,
      currURL: null,
      queryType: "",
      showInfo: false,
    };
  }

  componentDidMount() {
    if (!this.props.history.location.state) {
      this.props.history.push("/");
      return;
    }

    const images = this.props.history.location.state.batchData.keys;
    const batch = this.props.history.location.state.batchData.trashevents;
    const labelset = this.props.history.location.state.batchData.labelset;

    if (images.length === 0)
      return;

    const checkBatch = this.props.history.location.state.checkBatch;
    const gotBatchInfo = this.props.history.location.state.batchInfo !== undefined;
    this.setState({
      batchInfo: gotBatchInfo ? this.props.history.location.state.batchInfo : null,
      imageData: images,
      batchData: batch,
      labels: null,
      batchSize: images.length,
      checkBatch: checkBatch,
      consensusPhase: "select", /* 'select' is to choose which set of labels to accept, 'edit' to edit/finalize the selected set */
      prevURL: this.getURL(batch[images[this.state.currentIndex]].prev_image_id),
      currURL: this.getURL(batch[images[this.state.currentIndex]].image_id),
      queryType: this.props.history.location.state.queryType,
      showInfo: gotBatchInfo,
    });

    // offer only "active" labels from the new labelset
    getFetch("/get-labels?active=true", {})
      .then((data) => this.setState({labels: (Object.keys(labelset).length > 0 ? {...data.allLabels, ...labelset} : data.allLabels)}));
  }

  imageData(i) {
    return this.state.batchData[this.state.imageData[i]];
  }

  nextImage(labelSelection /*: LabelSelection[]*/, errors /*: number[]*/) {
    $("#root").css("background-color", "#fafafa");
    $(".success-checkmark").css("display", "block");
    $(".check-icon").show();

    setTimeout(function () {
      $(".check-icon").hide();
      $(".success-checkmark").css("display", "none");
    }, 1000);

    const currIndex = this.state.currentIndex;
    let batchCompleted = false;
    const currentBatchData = this.state.batchData;

    this.imageData(currIndex).label.label_info = [...labelSelection];
    this.imageData(currIndex).label.errors = [...errors];

    if (currIndex + 1 === this.state.batchSize)
      batchCompleted = true;
    else {
      const prevURL = this.getURL(this.imageData(currIndex + 1).prev_image_id);
      const currURL = this.getURL(this.imageData(currIndex + 1).image_id);

      this.setState({
        currentIndex: currIndex + 1,
        prevURL: prevURL,
        currURL: currURL,
        consensusPhase: "select"
      });
    }

    this.setState({batchData: currentBatchData});

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    let GATEWAY_URL = "";
    if (this.state.checkBatch && (this.state.queryType === "consensus" || this.state.queryType === "checking")) {
      // Start labelling one of the two checking batches (Consensus or 'Normal' Checking)
      GATEWAY_URL = "/label-check-trashevent";
    }
    else if (this.state.queryType === "random") {
      // Start labellig a "random" batch of previously unlabelled images (Wording random is from the Frontend Button)
      GATEWAY_URL = "/label-trashevent";
    }
    else {
      alert("Something went wrong, please contact the KITRO Tech team!");
      this.props.history.push("/");
    }

    const postData = {
      "trashevent": this.imageData(currIndex),
      "queryType": this.state.queryType,
    };

    getFetch(GATEWAY_URL, { method: "POST", data: postData })
      .then(() => {
      // when the batch is done, the component unmounts at some point, and the ref is `null`
        if (this.liveCountRef.current)
          this.liveCountRef.current.reload();
      }, (error) => {
        alert("The trashevent labels could not be saved: " + error);
        this.props.history.push({pathname: "/", state: {}});
      });

    if (batchCompleted) {
      if (this.state.checkBatch) {
        alert("Batch completed!");
        this.props.history.push("/");
      }
      else if (this.state.currentIndex === currIndex) {
        // Not updated
        this.props.history.push({
          pathname: "/batchDone",
          state: {batchID: this.imageData(this.state.currentIndex).batch_id}
        });
      }
      else {
        // Updated
        this.props.history.push({
          pathname: "/batchDone",
          state: {batchID: this.imageData(this.state.currentIndex - 1).batch_id}
        });
      }
    }
  }

  prevImage() {
    const currIndex = this.state.currentIndex;

    if (currIndex > 0) {
      const prevURL = this.getURL(this.imageData(currIndex - 1).prev_image_id);
      const currURL = this.getURL(this.imageData(currIndex - 1).image_id);

      this.setState(() => ({
        currentIndex: currIndex - 1,
        prevURL: prevURL,
        currURL: currURL,
        consensusPhase: "select"
      }));
    }
  }

  checkDisplay() {
    return (this.state.currentIndex <= 0) ? "none" : "default";
  }

  weightAllocation(oldLabel, e) {
    const newLabels = this.state.labelData;

    newLabels.forEach((label) => {
      if (label === oldLabel)
        label.weight = e.target.value;
    });

    this.setState({
      labelData: new Set(newLabels),
      viewMode: "L",
    });
  }

  getDateString() {
    let dateString = "";
    const myDate = new Date(this.imageData(this.state.currentIndex).datetime);
    dateString = myDate.toString().split("GMT")[0];
    return dateString;
  }

  getURL(image_key) {
    const params = {Bucket: "kitroengine-s3", Key: image_key};
    const url = s3.getSignedUrl("getObject", params);
    return url;
  }

  selectedLabeling(labelerId) {
    const imgId = this.state.imageData[this.state.currentIndex];
    const currImg = this.state.batchData[imgId];
    currImg.label = {
      label_info: [...currImg.labels[labelerId].label_info],
      errors: [...currImg.labels[labelerId].errors],
    };
    // delete currImg.labels;   // we can't delete this data in case users go back (Previous Image)
    this.setState({
      batchData: this.state.batchData,
      consensusPhase: "edit"
    });
  }

  getFilteredLabels(currentImageData) {
    if (!currentImageData)
      return [];
    return currentImageData.label.label_info.filter((li) => li.id in this.state.labels);
  }

  render() {
    if (!this.state.labels)
      return <></>;

    const handlers = {
      // 'nextImage': this.labelComplete() ? (e) => {e.preventDefault(); this.nextImage(null, null);} :  (e) => {e.preventDefault(); console.log("Label incomplete");},
      "fullPercent1": (e) => { e.preventDefault(); document.getElementById("makeFullPercent:0") && document.getElementById("makeFullPercent:0").click(); },
      "fullPercent2": (e) => { e.preventDefault(); document.getElementById("makeFullPercent:1") && document.getElementById("makeFullPercent:1").click(); },
    };

    const currentImageData = this.imageData(this.state.currentIndex);

    if (currentImageData) {
      if (currentImageData.weight >= 1000) {
        // alert("Caution! The scale is indicating an abnormally high amount of waste thrown in at once! Please adjust the labels accordingly!")
        $("#root").css("background-color", "#F4BD9F");
        $("#labelsContainerWindow").css("background-color", "#F4BD9F");
      }
      else {
        $("#root").css("background-color", "#fafafa");
        $("#labelsContainerWindow").css("background-color", "#fafafa");
      }
    }

    return (
      <HotKeys keyMap={map} handlers={handlers}>
        {this.state.showInfo ? <BatchInfo outlet_name={this.state.batchInfo.outlet_name} property_type={this.state.batchInfo.property_type} area_name={this.state.batchInfo.area_name} close_fn={() => this.setState({showInfo: false})} /> : ""}
        <div className="animated fadeIn">
          <h1 style={{marginTop: "-15px"}}>
            { currentImageData && this.getDateString()}
            { this.state.checkBatch && currentImageData.labelers && `- labelled by: ${Object.values(currentImageData.labelers).join(" / ")}`}
            <LiveCountComponent
              role="labelling"
              loadCallback={(maxed) => {
                if (maxed) {
                  alert("Today's maximum task count has been reached!");
                  this.props.history.push("/");
                }
              }}
              ref={this.liveCountRef}
            />
          </h1>
          <div
            style={{
              marginBottom: "5px", fontWeight: "bold", fontSize: "1rem", maxWidth: "64%"
            }}>
            <span style={{marginRight: "18%"}}>{"Area: " + (currentImageData && currentImageData.area)}</span>
            <span style={{marginRight: "18%"}}>{"Device ID: " + (currentImageData && currentImageData.image_id.split("/")[0].split("-").slice(-1)[0])}</span>
            <span>{"Added Weight: " + (currentImageData && currentImageData.weight) + "g"}</span>
          </div>
          <ImageContainer
            prevImage={this.state.prevURL == null ? null : this.state.prevURL}
            currImage={this.state.currURL == null ? null : this.state.currURL}
          />
          { this.state.checkBatch && (this.state.consensusPhase === "select") && <LabelingSelector
            onSelect={this.selectedLabeling}
            currentImageData={currentImageData}
            labels={this.state.labels}
          />}
          { (!this.state.checkBatch || (this.state.consensusPhase === "edit")) && <LabelsContainer
            nextImageFunction={this.nextImage}
            currentLabel={this.getFilteredLabels(currentImageData)}
            currentError={currentImageData && currentImageData.label.errors}
            currentImageData={currentImageData}
            labels={this.state.labels}
            topDistance={159}
            nextButtonString={"Next Image"}
            hasMake100={this.state.checkBatch}
          /> }
          <button key={this.state.currentIndex} type="button" className="btn btn-primary backBtn" style={{display: this.checkDisplay()}} onClick={this.prevImage}>Previous Image</button>
        </div>
      </HotKeys>
    );
  }
}

export default Labelling;
